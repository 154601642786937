<template>
  <div
    id="pagination"
    class="
      d-flex
      justify-content-center justify-content-md-between
      align-items-center
    "
  >
    <div class="d-none d-md-flex justify-content-center align-items-center">
      <CDropdown :toggler-text="perPage.toString()" placement="bottom-start">
        <CDropdownItem @click="changeItemsQuantity(10)"> 10 </CDropdownItem>
        <CDropdownItem @click="changeItemsQuantity(25)"> 25 </CDropdownItem>
        <CDropdownItem @click="changeItemsQuantity(50)"> 50 </CDropdownItem>
      </CDropdown>

      <span class="ml-2">Itens por página</span>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <span class="d-none d-md-block mr-2"
        >Página <b>{{ page }}</b> de <b>{{ pages }}</b
        >, <b>1</b> até <b>{{ items }}</b> de <b>{{ total }}</b> registros</span
      >
      <CPagination
        :active-page="page"
        :pages="pages"
        :dots="dots"
        align="center"
        :size="paginationSize"
        @update:activePage="handlePaginationUpdate"
      />
    </div>
  </div>
</template>

<script>
/**
 * @see https://coreui.io/vue/docs/components/pagination.html#cpagination-api
 */
export default {
  props: {
    /**
     * Current page number.
     */
    page: {
      type: Number,
      required: true
    },

    /**
     * Total number of pages.
     */
    pages: {
      type: Number,
      required: true
    },

    /**
     * Total number of items.
     */
    total: {
      type: Number,
      required: true
    },

    /**
     * Total of number of items paginate.
     */
    items: {
      type: Number,
      required: true
    },

    perPage: {
      type: Number,
      default: localStorage.getItem('perPageItem')
        ? parseFloat(localStorage.getItem('perPageItem'))
        : 10
    },

    /**
     * Align position of pagination. Valid values: 'start', 'center', 'end'.
     */
    align: {
      type: String,
      default: 'center'
    },

    /**
     * Sets dots elements (more content indicators) in pagination.
     */
    dots: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      paginationSize: undefined
    }
  },

  created() {
    this.handlerWindowSize()
  },

  methods: {
    /**
     * When the pagination update this method emit the update:active-page event
     * @public
     */
    handlePaginationUpdate(page) {
      this.$emit('update:active-page', page)
    },

    /**
     * Change quantity of items per page displayed on table
     * default quantity is 10
     * @public
     */
    changeItemsQuantity(quantity) {
      localStorage.setItem('perPageItem', quantity)

      this.$emit('update:perpage', quantity)

      const element = document.querySelector('#pagination')
      element.click()
    },

    /**
     * Verify and sets size of Pagination component
     */
    handlerWindowSize() {
      const handler = () => {
        if (window.innerWidth <= 768) {
          this.paginationSize = 'sm'
        } else {
          this.paginationSize = undefined
        }
      }

      window.addEventListener('resize', () => {
        handler()
      })

      handler()
    }
  }
}
</script>

<style lang="scss">
#pagination {
  .dropdown {
    button {
      color: $gray-900;
      background-color: $white;
      border-color: $gray-100;
    }
  }
  .pagination {
    margin-bottom: 0px;
  }

  .page-link {
    border-color: $gray-100;
    color: $gray-900;
  }

  .page-item.active {
    .page-link {
      color: #fff;
    }
  }
}
</style>
