<template>
  <div class="search-component">
    <CInput
      id="search"
      v-model="search"
      :placeholder="placeholder"
      name="search"
      class="w-100"
      @keypress.enter="updateValue"
      @input="updateValueDebounce"
    >
      <template #append>
        <CButton
          color="light"
          class="d-flex align-items-center search-component-button"
          @click="updateValue"
        >
          <CIcon name="cil-magnifying-glass" />
        </CButton>
      </template>
    </CInput>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    /**
     * Search value
     */
    value: {
      type: String,
      default: ''
    },

    /**
     * Input placeholder
     */
    placeholder: {
      type: String,
      default: 'Pesquisar por palavra-chave'
    }
  },

  data() {
    return {
      search: ''
    }
  },

  created() {
    this.setValue()
  },

  methods: {
    /**
     * Set search value on input
     * @public
     */
    setValue() {
      this.search = this.value
    },

    /**
     * Emit search value from input
     * @public
     */
    updateValue() {
      this.$emit('search', this.search)
    },

    /**
     * Debounce for search field
     * @public
     */
    updateValueDebounce: debounce(function () {
      this.updateValue()
    }, 500)
  }
}
</script>

<style lang="scss">
.search-component {
  .input-group-append {
    border: 1px solid $gray-200;
    border-left: 0px;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
